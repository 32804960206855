export function find<T>(array: T[], predicate: (arg0: T) => boolean) {
    let k = 0;
    while (k < array.length) {
        const value = array[k];
        if (predicate(value)) {
            return value;
        }
        k += 1;
    }
    return undefined;
}

export const getIcIdQueryParameter = (search: string): string | null => {
    const params = new URLSearchParams(search.toLowerCase());
    const icId = params.get('icid');

    if (
        icId &&
        typeof icId === 'string' &&
        icId.match(
            /[0-9a-f]{8}\-[0-9a-f]{4}\-[0-9a-f]{4}\-[0-9a-f]{4}\-[0-9a-f]{12}/,
        )
    ) {
        return icId;
    }
    return null;
};

export const getDateFormat = (date?: string): string | undefined => {
    if (!date) {
        return undefined;
    }
    try {
        return new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        })
            .format(new Date(date))
            .toString();
    } catch {
        return undefined;
    }
};