export const configDev = {
    environment: 'development',
    sgConnect: {
        authorizationEndpoint: 'https://sgconnect-hom.fr.world.socgen/sgconnect',
        unauthorizationEndpoint: 'https://shared-uat.sgmarkets.com/unauthorized/?redirectUri=https://insight-dev.sgmarkets.com',
        clientId: '089fd343-11d8-405f-8445-a744daa4ddfa',
        scope: 'openid profile mail api.pretrade-gateway-subscription.v1 api.insightdomain.v1 api.pretrade-insight.v1 api.subscribe-publication.v1 api.pretrade-third-parties.v1 api.idy-widgets.request-access',
        level: 'L1',
    },
    gatewayUrl: 'https://pretrade-gateway-subscription-dev.sgmpinsight.dev.euw.gbis.sg-azure.com/api',
    apiSubscriptionUrl: 'https://pretrade-api-subscription-dev.sgmpinsight.dev.euw.gbis.sg-azure.com/api',
    apiThirdPartiesUrl: 'https://pretrade-api-thirdparties-dev.sgmpinsight.dev.euw.gbis.sg-azure.com/api',
    icManageAccessUrl: 'https://ic-hom.fr.world.socgen/v2/sgmaccess/{icid}',
    requestAccessId: '95a4830c-a0b1-467e-80fb-26ebd39381b8',
    sgDashboard: {
        url: 'https://sg-dashboard-uat2.fr.world.socgen',
        boards: {
            contactReadership: 19558,
        },
        selectors: {
            icSingleContactPicker: 18,
            icMultiContactPicker: 3686,
        },
    },
    widgetCdnBaseUrl: 'https://sgwt-widgets-dev.sgmarkets.com',
    sharedSgMarketsBaseUrl: 'https://shared-uat.sgmarkets.com',
};
